<template>
    <v-row id="error-page" class="flex-column" align="center" justify="center">
        <h1 class="secondary--text text-center font-weight-bold pa-0 mt-4">404</h1>
        <div class="text-h4 text-center primary--text font-weight-bold mb-4">PAGE NOT FOUND !</div>
        <div class="text-center text-capitalize mt-6 mb-1">
            YOU SEEM TO BE TRYING TO FIND THIS WAY HOME
        </div>
        <v-btn class="ma-2" outlined color="primary" @click="$router.push('/')">Back To Home</v-btn>
    </v-row>
</template>
<script>
export default {
    name: 'Error',
    metaInfo: {
        title: '404 | Page Not Found',
    }
}
</script>